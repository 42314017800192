<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.username" v-if="!isMerchant" class="account">
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="search.mobile" class="mobile">
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="showName">
        <el-input v-model="search.merchantName" class="mobile">
          <template slot="prepend">商户名称</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item v-if="isMerchant">
        <el-select v-model="search.roleId" placeholder="XJD" v-if="pro">
          <el-option
            v-for="item in xjdOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isMerchant">
        <el-select v-model="search.isPromote" placeholder="共享" v-if="pro">
          <el-option
            v-for="item in promoteOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="showExam">
        <el-select v-model="search.status" placeholder="审查结果">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    showExam: {
      type: Boolean,
      default: true
    },
    isMerchant: {
      type: Boolean,
      default: false
    },
    showName: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      search: {
        username: '',
        mobile: '',
        merchantName: '',
        verify: null,
        roleId: null,
        isPromote: null,
        status: null
      },
      date: [],
      // 状态选项
      xjdOpts: [
        { value: '', label: '请选择' },
        { value: 2, label: '已开通' },
        { value: -1, label: '未开通' }
      ],
      promoteOpts: [
        { value: '', label: '请选择' },
        { value: 1, label: '已开启' },
        { value: 0, label: '未开启' }
      ],
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '未处理' },
        { value: 1, label: '通过' },
        { value: 2, label: '拒绝' },
        { value: -1, label: '黑名单' },
        { value: 3, label: '资料不全' }
      ]
    };
  },
  methods: {
    searchList() {
        let [startTime = '', endTime = ''] = this.date || [];
        let param = startTime && endTime ? { startTime, endTime, ...this.search } : this.search;
        this.$emit('search', param);
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 160px;
  }
  .mobile.el-input {
    width: 200px;
  }
  .el-select {
    width: 120px;
  }
  .time {
    width: 350px;
    padding: 3px;
  }
}
</style>
